window.AssessConfig = {
  PUBLIC_API_URL: "#{publicApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  DATA_INTEGRITY_URL: "#{dataIntegrityUrl}#",
  ASSESS_URL: "#{assessUrl}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PORTAL_URL: "#{portalUrl}",
  AUTH0_CLIENTID: "#{auth0ClientId}#",
  AUTH0_DOMAIN: "#{auth0Domain}#",
};
